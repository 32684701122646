@import '../node_modules/ng-zorro-antd/ng-zorro-antd.min.css';
@import '../node_modules/@akebono/core/styles.scss';

a {
  color: darkblue;
}

#goog-gt-tt,
.goog-tooltip,
.skiptranslate > iframe {
  display: none !important;
}
.goog-tooltip:hover {
  display: none !important;
}
.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.ant-tabs-content-holder {
  overflow: auto;
}

body {
  top: 0 !important;
}

.goog-te-banner-frame {
  display: none !important;
}
